<template>
  <v-sheet
      elevation="0"
      class="pa-2 px-0"
      v-if="images.length > 0"
  >
    <v-slide-group
        ref="slideImage"
        v-model="model"
        :mobile-breakpoint="breakpoint"
        show-arrows="always"
    >
      <v-slide-item
          v-for="(img, index) in sortedImages"
          :key="index"
          v-slot="{ active }"
          class="mx-1"
      >
        <v-img id="selected"
               :lazy-src="img[property]"
               :src="img[property]"
               :aspect-ratio="16 / 9"
               :width="300"
        />
      </v-slide-item>
    </v-slide-group>
    <div class="d-flex justify-center align-center align-content-center my-1">
      <v-btn text icon @click="clickLeft" :disabled="isPreviousBtnDisable"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <v-btn text icon @click="clickRight" :disabled="isNextBtnDisable"><v-icon>mdi-chevron-right</v-icon></v-btn>
    </div>
  </v-sheet>
</template>

<script>
import {generalMixin} from "@/mixins/general.mixin";

export default {
  name: "SlideShowComponent",
  mixins: [generalMixin],

  props: {
    images: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    property: {
      type: String,
      required: true
    },
    breakpoint: {
      type: String,
      required: false,
      default: 'sm'
    }
  },

  data: () => ({
    model: null,
    previousButton: "",
    nextButton: "",
    isPreviousBtnDisable: true,
    isNextBtnDisable: false,
  }),

  mounted() {
    setTimeout(() => {
      this.checkNavBtnStatus();

      if (this.isPreviousBtnDisable && this.isNextBtnDisable)
        this.isNextBtnDisable = false;
    }, 2000);
  },

  computed: {
    sortedImages() {
      return _.orderBy(this.images, ['id'], ['asc']);
    }
  },

  methods: {
    checkNavBtnStatus() {
      this.previousButton = this.$el.querySelector(".v-slide-group__prev");
      this.nextButton = this.$el.querySelector(".v-slide-group__next");
      this.isPreviousBtnDisable = this.previousButton.className.includes("disabled");
      this.isNextBtnDisable = this.nextButton.className.includes("disabled");
    },
    clickLeft() {
      this.previousButton.click();
      this.$nextTick(() => {
        this.checkNavBtnStatus();
      });
    },
    clickRight() {
      this.nextButton.click();
      this.$nextTick(() => {
        this.checkNavBtnStatus();
      });
    }
  }
}
</script>

<style scoped>
:deep(.v-slide-group__next) {
  display: none !important;
}

:deep(.v-slide-group__prev) {
  display: none !important;
}

:deep(.v-slide-group__wrapper) {
  background-color: transparent !important;
  margin: 0 !important;
}

#selected {
  cursor: pointer !important;
}

button {
  background-color: transparent !important;
}

button i {
  color: #143245 !important;
}
</style>