<template>
  <v-container class="py-0 my-0">
    <label style="font-size: 10px" v-if="hideDetails && !hideInput">
      {{ disabled ? message : `&nbsp;` }}
    </label>
    <v-file-input
      v-model="file"
      @click:clear="$refs[reference].reset()"
      :hide-details="hideDetails"
      :disabled="disabled"
      dense
      color="secondary"
      outlined
      light
      persistent-hint
      :label="$t('general.fields.selectImage')"
      @change="onFileChange($event)"
      accept="image/*"
      :ref="reference"
      :rules="verifyImageSizeAndFormat(file)"
      :append-icon="appendIcon ? 'mdi-file-image-outline' : ''"
      prepend-icon=""
      :messages="disabled ? message : ''"
      v-show="!hideInput"
    >
    </v-file-input>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="hideInput"
          v-on="on"
          small
          fab
          color="white"
          @click="onButtonClick"
        >
          <v-icon color="secondary">mdi-file-upload</v-icon>
        </v-btn>
      </template>
      <span style="font-size: 10px">
        {{ $t("general.buttons.upload") }}
      </span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="80%" height="500px">
      <v-card>
        <v-card-title class="headline">
          {{ $t('general.titles.cropImageTitle') }}
        </v-card-title>
        <v-card-text>
          <div>
            <div id="image-cropper">
              <img ref="image" :src="imageSrc" />
            </div>
          </div>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-btn-toggle v-model="aspectRatio" color="secondary" dense>
            <v-btn
              v-if="type === 'logo' || type === 'badge'"
              class="px-4"
              :value="1"
              @click="changeAspectRatio(1)"
            >
              1:1
            </v-btn>
            <v-btn
              v-if="type !== 'badge'"
              class="px-4"
              :value="16/9"
              @click="changeAspectRatio(16 / 9)"
            >
              16:9
            </v-btn>
          </v-btn-toggle>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="cancelDialog()">
            {{ $t('general.buttons.cancel') }}
          </v-btn>
          <v-btn color="secondary" text @click="cropImage">
            {{ $t('general.buttons.crop') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import CompactImages from "@/services/CompactImages";

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
      default: "",
    },
    reference: {
      type: String,
      required: false,
      default: "fileInput",
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    appendIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      file: null,
      dialog: false,
      imageSrc: null,
      cropper: null,
      croppedImage: null,
      isCropping: false,
      aspectRatio: 1,
    };
  },

  computed: {
    ...mapGetters({
      verifyImageSizeAndFormat: "general/verifyImageSizeAndFormat",
    }),
  },

  methods: {
    changeAspectRatio(aspectRatio) {
      if (this.cropper) {
        this.cropper.setAspectRatio(aspectRatio);
      } else {
        this.aspectRatio = aspectRatio;
      }
    },
    onFileChange(event) {
      if (this.file) {
        if (!this.file.type.startsWith("image/")) {
          this.$toast.error(this.$t("general.rules.invalidFormat"));
          this.file = null;
          return;
        }

        if (this.file.size > 5000000) {
          this.$emit("rule:size", true);
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          this.openCropper(e.target.result);
        };
        reader.readAsDataURL(this.file);
      }
    },
    onButtonClick() {
      console.log(this.reference);
      this.file = null;
      this.$refs[this.reference].$refs.input.click();
    },
    openCropper(imageSrc) {
      const aspectRatio = [this.type === "logo" || this.type === "badge" ? 1 : 16 / 9] || 16 / 9;
      this.imageSrc = imageSrc;
      this.dialog = true;
      this.$nextTick(() => {
        if (this.cropper) {
          this.cropper.destroy();
        }
        this.cropper = new Cropper(this.$refs.image, {
          aspectRatio: aspectRatio,
          autoCropArea: 1,
          responsive: true,
          modal: true,
          guides: true,
          viewMode: 1,
          crop: () => {
            this.isCropping = true;
            clearTimeout(this.debounceUpdate);
            this.debounceUpdate = setTimeout(() => {
              this.updatePreview();
            }, 300); // Update preview every 300 milliseconds
          },
        });
      });
    },
    cropImage() {
      const canvas = this.cropper.getCroppedCanvas({
        width: 1920,
        height: 1080,
        fillColor: "#00000000",
        imageSmoothingEnabled: true,
        imageSmoothingQuality: "high",
      });
      canvas.toBlob((blob) => {
        const scope = this;
        CompactImages.downscaleImage(0.7, blob, function (data) {
          if (data.size <= 5000000) {
            if (scope.type === "image")
              scope.$emit("update:images", { url: URL.createObjectURL(data) });
            if (scope.type === "logo")
              scope.$emit("update:logo", { url: URL.createObjectURL(data) });
            if (scope.type === "badge")
              scope.$emit("update:badgesFiles", { url: URL.createObjectURL(data) });
          } else {
            scope.$emit("rule:size", true);
          }
        });
      });
      this.$emit("update:documents", true);

      this.cropper.destroy();
      this.$refs[this.reference].reset();

      this.dialog = false;
    },
    updatePreview() {
      this.cropper
        .getCroppedCanvas({
          width: 1920,
          height: 1080,
          fillColor: "#00000000",
          imageSmoothingEnabled: true,
          imageSmoothingQuality: "high",
        })
        .toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          this.croppedImage = url;
          this.isCropping = false;
        });
    },
    cancelDialog() {
      this.cropper.destroy();
      this.$refs[this.reference].reset();
      this.file = null;

      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.selected-aspect-ratio {
  background-color: #03ac8c;
}

#image-cropper img {
  object-fit: contain;
  width: 80%;
  height: auto;
  aspect-ratio: 16 / 9;
  background-color: transparent;
}

:deep(#image-preview .v-image) {
  margin: 10px !important;
}
</style>
