import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.images.length > 0)?_c(VSheet,{staticClass:"pa-2 px-0",attrs:{"elevation":"0"}},[_c(VSlideGroup,{ref:"slideImage",attrs:{"mobile-breakpoint":_vm.breakpoint,"show-arrows":"always"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.sortedImages),function(img,index){return _c(VSlideItem,{key:index,staticClass:"mx-1",scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c(VImg,{attrs:{"id":"selected","lazy-src":img[_vm.property],"src":img[_vm.property],"aspect-ratio":16 / 9,"width":300}})]}}],null,true)})}),1),_c('div',{staticClass:"d-flex justify-center align-center align-content-center my-1"},[_c(VBtn,{attrs:{"text":"","icon":"","disabled":_vm.isPreviousBtnDisable},on:{"click":_vm.clickLeft}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VBtn,{attrs:{"text":"","icon":"","disabled":_vm.isNextBtnDisable},on:{"click":_vm.clickRight}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }